/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';

// any CSS you import will output into a single css file (app.css in this case)
import './styles/variant-landing-page/mods/assets/css/core/bootstrap.css?ver=4.3.25';
import './styles/variant-landing-page/mods/assets/css/font-awesome/css/font-awesome.min.css?ver=4.3.25';
import './styles/variant-landing-page/mods/assets/css/style.css?ver=4.3.25';
import './styles/variant-landing-page/mods/assets/css/template-style/template.css?ver=4.3.25';
import './styles/variant-landing-page/assets/css/bootstrap.css?ver=4.3.25';
import './styles/variant-landing-page/style.css?ver=4.3.25';
import './styles/variant-landing-page/mods/assets/css/form-style/form.css';

import './styles/app.css';

// start the Stimulus application
import './bootstrap';
